import GlobalHttpService from "./GlobalHttpService"

class GlobalService extends GlobalHttpService {
  getUserInfo() {
    return this.get("/api/ka/me")
  }

  postUserInfo(data: any) {
    return this.post("/api/ka/me", data)
  }

  updatePassword(data: { current_password: string; password: string }) {
    return this.post("/api/ka/password", data)
  }

  updatePhone(data: { phone: string; code: string }) {
    return this.post("/api/ka/phone/", data)
  }
}

export default new GlobalService()
