import jwt, { JwtPayload } from "jsonwebtoken"
import Cookie from "./Cookie"
import { ACCESS_TOKEN_NAME } from "src/env"

export function parseJwt(token: string) {
  if (!token) return null

  try {
    const base64Url = token.split(".")[1]
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    const payload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(c => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join("")
    )

    return JSON.parse(payload)
  } catch (error) {
    console.error("Error in parsing JWT", error)

    return null
  }
}

export function jwtVerify(accessToken: string, cb: any) {
  jwt.verify(accessToken, "any", (error: any, accessTokenData: any = {}) => {
    const { data: user } = accessTokenData
    if (error || !user) {
      return cb(null)
    }
    const userData = getBase64(user)

    return cb(userData)
  })
}

export function getUserData(accessToken: string) {
  if (!accessToken) {
    return null
  }

  try {
    const decoded = jwt.decode(accessToken) as JwtPayload
    if (decoded && decoded.data) {
      return decoded.data
    } else {
      console.error("Decoded token does not contain data property")
      return null
    }
  } catch (error) {
    console.error("Error decoding token:", error)
    return null
  }
}

export function getBase64(value: string): any {
  let buffer

  if (isString(value)) {
    buffer = Buffer.from(value, "base64").toString("ascii")
  }

  if (isJson(buffer!)) {
    buffer = JSON.parse(Buffer.from(value, "base64").toString("ascii"))
  }

  return buffer
}
export function isString(v: unknown): boolean {
  return isDefined(v) && typeof v === "string"
}
export function isJson(str: string): boolean | any {
  if (!str || str === null) {
    return false
  }

  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }

  return true
}
export function isDefined(v: unknown): boolean {
  return typeof v !== "undefined" && v !== null
}

export function getUserTnetId() {
  const token = Cookie.get(ACCESS_TOKEN_NAME)
  if (token) {
    const userData = getUserData(token)
    return userData?.user_id || ''
  } else {
    return ''
  }
}
