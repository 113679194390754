import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { ACCESS_TOKEN_NAME } from "src/env"
import GlobalService from "src/server/services/GlobalService"
import { ITokenTnetUser } from "src/types/commonTypes"
import Cookie from "src/utils/configs/Cookie"
import { getUserData } from "src/utils/configs/jwt"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"

export interface User_Context {
  tnetUserData?: ITokenTnetUser
  isConnected: boolean
  isLoading: boolean
}
// Create a context for the user
const UserContext = createContext<User_Context>({
  isConnected: false,
  tnetUserData: undefined,
  isLoading: false
})

const fetchUserData = async () => {
  try {
    const response: any = await GlobalService.getUserInfo()
    return response.data.data
  } catch (error: any) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        Cookie.remove(ACCESS_TOKEN_NAME)
      }
    } else {
      console.log("An unexpected error occurred:", error)
    }
  }
}

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const AccessToken = Cookie.get(ACCESS_TOKEN_NAME)

  const {
    data: tnetUserData,
    isLoading,
    error
  } = useQuery<ITokenTnetUser>({
    queryKey: [QUERY_KEYS.GET_GLOBAL_USER_INFO, AccessToken],
    queryFn: fetchUserData,
    enabled: !!AccessToken, // Only run query if we have an access token
    retry: false, // Don't retry on failure
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000 // Keep data in cache for 30 minutes
  })

  const context = {
    tnetUserData,
    isLoading: isLoading && !!AccessToken,
    isConnected: !!AccessToken
  }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

// Create a custom hook to use the UserContext
export const useUserContext = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error("useUserContext must be withing UserProvider")
  }

  return context
}
